type Environment = "production" | "development";

const environment: Environment = (process.env.REACT_APP_ENV as Environment) || "development";

const config = {
    baseUrl: process.env.REACT_APP_BASE_URL,
    environment,
    isProduction: environment === "production",
    blockchainUrls: {
        production: "https://eosflare.io/tx/",
        development: "https://jungle4.cryptolions.io/v2/explore/transaction/",
    }
}

export default config;
